import React from "react"

import SwiperCore, { EffectFade } from "swiper"

import Layout from "../components/layout"

import { graphql, useStaticQuery } from "gatsby"

import styles from "./interiors.module.scss"
import { InteriorGard } from "../components/card/"

SwiperCore.use([EffectFade])

const InteriorsPage = ({ location }) => {
  const { allContentfulInterior } = useStaticQuery(
    graphql`
      query {
        allContentfulInterior(filter: { node_locale: { eq: "uk-UA" } }) {
          edges {
            node {
              name
              photos {
                fluid(maxWidth: 1200) {
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    `
  )

  const interiors = allContentfulInterior.edges
    .sort(() => Math.random() - 0.5)
    .map(edge => edge.node)
    .filter(interior => interior && interior.photos && interior.photos.length)

  const title = "Інтер'єри"
  const description =
    "Виконуємо ремонти будинків в різних стилях. Приклади останніх робіт."

  return (
    <Layout
      contentClassName="layout-content"
      title={title + `. ${new Date().getFullYear()} рік.`}
      description={description}
      location={location}
    >
      <h1>{title}</h1>
      <p>Приклади інтер'єрів виконаних нами</p>
      <div className={styles.interiors}>
        {interiors.map(interior => (
          <InteriorGard interior={interior} key={interior.name} />
        ))}
      </div>
    </Layout>
  )
}

export default InteriorsPage
